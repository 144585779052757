import * as React from 'react';

import { FormattedMessage } from 'react-intl';

/**
 * This component contains headings for the polling location lookup UI and is
 * used in two places:
 *   1) the main IWV site
 *   2) the IWV polling lookup widget
 *
 * Because the widget is publicly released for developer use, the CSS
 * classnames here are namespaced in order to be unique when incorporated into
 * third-party websites.
 */
const FormHeaderBase: React.FC<{ prefix: string }> = ({ prefix }) => (
  <>
    <h1 className={`${prefix}-locate-form__header`}>
      <FormattedMessage
        defaultMessage="Find where you can vote"
        id="locate.searchForm.header"
      />
    </h1>
    <p className={`${prefix}-locate-form__subheader`}>
      <FormattedMessage
        defaultMessage={`
Different states have different voting options.
Enter your home address, including apartment or unit number
(e.g. Apt 1 or Unit G), and we’ll show your choices.
`}
        id="locate.searchForm.subheader"
      />
    </p>
  </>
);

const FormHeader: React.FC = () => <FormHeaderBase prefix="iwv" />;

const WidgetFormHeader: React.FC = () => <FormHeaderBase prefix="widget" />;

export { FormHeader, WidgetFormHeader };
