import * as React from 'react';

import { IntlProvider } from 'react-intl';

import {
  Configuration,
  Jurisdiction,
  ActiveLocale,
  ActiveLanguageToLocale,
} from '@dnc/baseline';
import { LocaleContext } from '@dnc/shared-components';

import {
  DEFAULT_LOCALE,
  DEFAULT_RICH_TEXT_ELEMENTS,
  locales,
} from '../../../i18n/i18n-config';

import WidgetForm from './WidgetForm';

export type WidgetConfiguration = {
  campaign?: string;
  locale?: ActiveLocale;
  state?: Jurisdiction;
  showHeader?: boolean;
};

type WidgetRootProps = {
  config?: WidgetConfiguration;
};

// We need a Root component for React Hot Loading.
class WidgetRoot extends React.Component<WidgetRootProps> {
  readonly searchFormUrl: string;

  readonly defaultLocale: ActiveLocale = ActiveLanguageToLocale['English'];

  constructor(props: WidgetRootProps) {
    super(props);

    const configuration =
      process.env.CONFIGURATION || Configuration.development;
    this.searchFormUrl =
      configuration === Configuration.development
        ? 'https://iwillvote-staging.go.akamai-access.com/locate/results'
        : 'https://iwillvote.com/locate/results';
  }

  render() {
    const { campaign, locale, showHeader, state } = this.props.config || {};

    const currentLocale = locale || this.defaultLocale;

    // Suppress console warnings about non-precompiled messages in tests
    const onWarn = process.env.NODE_ENV === 'test' ? () => {} : undefined;

    return (
      <LocaleContext.Provider value={currentLocale}>
        <IntlProvider
          locale={currentLocale}
          defaultLocale={DEFAULT_LOCALE}
          messages={locales[currentLocale].messages}
          defaultRichTextElements={DEFAULT_RICH_TEXT_ELEMENTS}
          onWarn={onWarn}
        >
          <WidgetForm
            campaign={campaign || ''}
            searchFormUrl={this.searchFormUrl}
            selectedState={state}
            showHeader={showHeader || false}
          />
        </IntlProvider>
      </LocaleContext.Provider>
    );
  }
}

export default WidgetRoot;
